jQuery(document).ready(function () {
    "use strict";
    var $formWrapper = $('.consultation-form'),
        $form =  $('#consultationForm'),
        $popupTY = $('#popup__thank-you');

    if($form.length){
        // Variable to hold request
        var request;

        // Прив'язка до події відправлення нашої форми
        $form.on('submit', function(event){

            if ($form.valid()) {
                // Заборонити публікацію форми за замовчуванням - працюйте тут у разі помилок
                event.preventDefault();

                // Скасувати будь-який запит
                if (request) {
                    request.abort();
                }

                $formWrapper.addClass('_sending'); // loader

                // налаштувати деякі локальні змінні
                var $this = $(this);

                // Виберемо та закешуємо всі поля
                var $inputs = $this.find("input, select, button, textarea");

                // Серіалізуйте дані у формі
                var serializedData = $this.serialize();

                // Відключимо входи під час запиту Ajax.
                // Примітка: ми відключаємо елементи ПІСЛЯ серіалізації даних форми.
                // Відключені елементи форми не будуть серіалізовані.
                $inputs.prop("disabled", true);

                // Fire off the request to /sendmail.php
                request = $.ajax({
                    url: "/sendmail.php",
                    type: "post",
                    data: serializedData
                });

                // Обробник зворотного виклику, який буде викликаний у разі успіху
                request.done(function (response, textStatus, jqXHR){
                    document.getElementById("consultationForm").reset();
                    $popupTY.addClass('popup__open');
                    $('body').addClass('popup__opened');
                    console.log("Hooray, it worked!");
                    $formWrapper.removeClass('_sending'); // loader
                    setTimeout(()=>{
                        $popupTY.removeClass('popup__open');
                        $('body').removeClass('popup__opened');
                    }, 4000);
                });

                // Обробник зворотного виклику, який буде викликатись у разі збою
                request.fail(function (jqXHR, textStatus, errorThrown){
                    // Log the error to the console
                    $formWrapper.removeClass('_sending'); // loader
                    console.error(
                        "The following error occurred: "+
                        textStatus, errorThrown
                    );
                });

                // Callback handler that will be called regardless
                // if the request failed or succeeded
                request.always(function () {
                    // Reenable the inputs
                    $inputs.prop("disabled", false);
                });
            } else {
                //console.log('no valide');
            }

        });

        function getMsg(selector, p) {
            if(p=='req'){
                return $(selector).attr('data-msg-req');
            }
            if(p=='min'){
                return $(selector).attr('data-msg-min');
            }
            if(p=='max'){
                return $(selector).attr('data-msg-max');
            }
            if(p=='valid'){
                return $(selector).attr('data-msg-valid');
            }
        }

        $form.validate({
            errorElement: 'span',
            rules:{
                user_first_name:{
                    required: true,
                    minlength: 3,
                    maxlength: 100
                },
                user_family_name:{
                    required: true,
                    minlength: 3,
                    maxlength: 100
                },
                user_email:{
                    required: true,
                    email: true,
                    regex: /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                    minlength: 5,
                    maxlength: 100
                },
                user__message:{
                    required: true,
                    minlength: 3
                }
            },
            messages:{
                user_first_name:{
                    required: getMsg('#user_first_name', 'req'),
                    minlength: getMsg('#user_first_name', 'min'),
                    maxlength: getMsg('#user_first_name', 'max')
                },
                user_family_name:{
                    required: getMsg('#user_family_name', 'req'),
                    minlength: getMsg('#user_family_name', 'min'),
                    maxlength: getMsg('#user_family_name', 'max')
                },
                user_email:{
                    required: getMsg('#user_email', 'req'),
                    minlength: getMsg('#user_email', 'min'),
                    maxlength: getMsg('#user_email', 'max'),
                    email:  getMsg('#user_email', 'valid'),
                    regex: getMsg('#user_email', 'valid')
                },
                user__message:{
                    required: getMsg('#user__message', 'req'),
                    minlength: getMsg('#user__message', 'min'),
                }
            }
        });

        $.validator.addMethod(
            /* The value you can use inside the email object in the validator. */
            "regex",

            /* The function that tests a given string against a given regEx. */
            function(value, element, regexp)  {
                /* Check if the value is truthy (avoid null.constructor) & if it's not a RegEx. (Edited: regex --> regexp)*/

                if (regexp && regexp.constructor != RegExp) {
                    /* Create a new regular expression using the regex argument. */
                    regexp = new RegExp(regexp);
                }

                /* Check whether the argument is global and, if so set its last index to 0. */
                else if (regexp.global) regexp.lastIndex = 0;

                /* Return whether the element is optional or the result of the validation. */
                return this.optional(element) || regexp.test(value);
            }
        );
    }

});